import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'

const exampleInitialState = {
  user: null,
  notificationsCount: null
}

export const actionTypes = {
  UPDATE: 'UPDATE'
}

// REDUCERS
export const reducer = (state = exampleInitialState, action) => {
  switch (action.type) {

    case actionTypes.UPDATE:
      return Object.assign({}, state, {
        user: action.user
      })

    case "setNotificationsCount":
      return Object.assign({}, state, {
        notificationsCount: action.notificationsCount
      })
  
    default: return state
  }
}

// ACTIONS

export const updateUser = (user) => dispatch => {
  return dispatch({ type: actionTypes.UPDATE, user: user })
}

export const setNotificationsCount = (number) => dispatch => {
  return dispatch({ type: "setNotificationsCount", notificationsCount: number })
}

export const initStore = (initialState = exampleInitialState) => {
  return createStore(reducer, initialState, composeWithDevTools(applyMiddleware(thunkMiddleware)))
}